@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: var(--blizzard-blue);
  --secondary: var(--mine-shaft);
  --main-text-color: var(--white1);

  --list-title-color: var(--blizzard-blue);
  --list-company-color: var(--pink-salmon);
  --list-loc-color: var(--white1);
  --visited-color: var(--perano);

  /* profile */
  --main-border-color: hsla(0, 0%, 100%, 0.24);
  --custom-weight: 700;
  --page-text-color: var(--white1);
  --page-text-color2: hsl(0, 0%, 60%);
  --main-border-radius: 8px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
}

body {
  font-family: 'Trebuchet MS', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  min-height: 100vh;
  color: #fff;
  background-color: var(--tundora);
}

.container {
  max-width: 1072px !important;
  margin: auto;
}

.hidden {
  display: none;
}

.header-background {
  background: var(--bittersweet);
  @media only screen and (min-width: 400px) {
    background: var(--bittersweet) url('/kd/header-background.svg') no-repeat center bottom;
  }
}

.homepage-background {
  background: var(--bittersweet) url('/kd/homepage-cloud.svg') no-repeat center bottom;
}

@media (min-width: 1024px) {
  .main {
    padding: 5rem 0;
  }
}

.popup-bg-blur-kd {
  backdrop-filter: blur(5.699999809265137px);
  background: rgba(0, 0, 0, 0.32);
}

.custom-shadow-kd{
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
}
